export default function ErrorSection({ pageError }) {
  if (pageError) {
    return (
      <>
        <hr />
        <div className="text-danger">{pageError}</div>
        <small>
          <em>Correct the above error(s) to proceed further</em>
        </small>
      </>
    );
  }
  return <></>;
}

/* eslint-disable no-undef */
export const AppUtils = {
  showDialog: function (dialogId) {
    $("#" + dialogId).modal("show");
  },

  hideDialog: function (dialogId) {
    $("#" + dialogId).modal("hide");
  },

  getFormData: function (formId) {
    var formEl = document.getElementById(formId);

    if (formEl) {
      return new FormData(formEl);
    }

    return null;
  },

  getFormDataWithFormObject: function (form) {
    //var formEl = document.getElementById(formId);

    if (form) {
      return new FormData(form);
    }

    return null;
  },

  createFormData: function (object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  },

  createURLSearchParams: function (object) {
    const encodedParams = new URLSearchParams();
    Object.keys(object).forEach((key) => encodedParams.set(key, object[key]));
    return encodedParams;
  },

  getValueFromArray: function (valueArray, matchField, matchValue, value) {
    return valueArray.filter((x) => x[matchField] === matchValue)[0][value];
  },

  formatDate: (datetime) => {
    const dateObj = new Date(datetime);
    const today = new Date();

    if (dateObj.toDateString() === today.toDateString()) {
      // Case 1: Today's date
      const time = dateObj.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return time;
    } else if (dateObj.getFullYear() === today.getFullYear()) {
      // Case 2: Within the current year
      const date = dateObj.toLocaleString("en-US", { month: "short", day: "numeric" });
      return date;
    } else {
      // Case 3: Previous year
      const formattedDate = dateObj.toLocaleString("en-US", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
      return formattedDate;
    }
  },
};

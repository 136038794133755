import { useContext, useEffect } from "react";
import WishPreviewSection from "../../../components/WishPreviewSection";
import EnrollmentContext from "../../../contexts/EnrollUserContext";
import useEnrollment from "../../../services/useEnrollment";

export default function PreviewPage() {
  const { enrollmentDetails, setCurrentPage, termsAgreed, setTermsAgreed, PageTitles } = useContext(EnrollmentContext);
  const { terms, downloadTerms, enrollmentMasterData } = useEnrollment(1);
  
  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <WishPreviewSection
            title={PageTitles[0]}
            onEditClicked={() => {
              setCurrentPage(0);
            }}
          >
            <tr>
              <td>Title</td>
              <td>
                {
                  enrollmentMasterData?.titles?.find((item) => {
                    return item.id === enrollmentDetails.basicDetails.title_id;
                  })?.title_name
                }
              </td>
            </tr>
            <tr>
              <td>First Name</td>
              <td>{enrollmentDetails.basicDetails.first_name}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{enrollmentDetails.basicDetails.second_name}</td>
            </tr>
            <tr>
              <td>Date of birth</td>
              <td>{enrollmentDetails.basicDetails.dob ? new Date(enrollmentDetails.basicDetails.dob).toDateString() : "No data found"}</td>
            </tr>
            <tr>
              <td>Preferred Language</td>
              <td>
                {
                  enrollmentMasterData?.languages?.find((item) => {
                    return item.id === enrollmentDetails.basicDetails.language_id;
                  })?.title_name
                }
              </td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>
                {
                  enrollmentMasterData?.gender?.find((item) => {
                    return item.id === enrollmentDetails.basicDetails.gender_id;
                  })?.title_name
                }
              </td>
            </tr>
            <tr>
              <td>Maritial Status</td>
              <td>
                {
                  enrollmentMasterData?.marital_status?.find((item) => {
                    return item.id === enrollmentDetails.basicDetails.marital_status_id;
                  })?.title_name
                }
              </td>
            </tr>
            <tr>
              <td>Profession</td>
              <td>
                {
                  enrollmentMasterData?.profession?.find((item) => {
                    return item.id === enrollmentDetails.basicDetails.profession_id;
                  })?.title_name
                }
              </td>
            </tr>
            <tr>
              <td>Monthly Income</td>
              <td>
                {
                  enrollmentMasterData?.monthly_income?.find((item) => {
                    return item.id === enrollmentDetails.basicDetails.monthly_income_id;
                  })?.title_name
                }
              </td>
            </tr>
            <tr>
              <td>Aadhar</td>
              <td>{enrollmentDetails.basicDetails.aadhar_no}</td>
            </tr>
            <tr>
              <td>GST Number</td>
              <td>{enrollmentDetails.basicDetails.gst_no}</td>
            </tr>
            <tr>
              <td>PAN</td>
              <td>{enrollmentDetails.basicDetails.pan_no}</td>
            </tr>
            <tr>
              <td>Aadhar Card (Attachment)</td>
              <td>
                {enrollmentDetails.basicDetails.aadhar_file?.name ? (
                  <a href="javascript:void();">
                    <i className="las la-file-alt la-2x"></i>
                    {enrollmentDetails.basicDetails.aadhar_file?.name}
                  </a>
                ) : (
                  "No attachment"
                )}
              </td>
            </tr>
            <tr>
              <td>PAN Card (Attachment)</td>
              <td>
                {enrollmentDetails.basicDetails.aadhar_file?.name ? (
                  <a href="javascript:void();">
                    <i className="las la-file-alt la-2x"></i>
                    {enrollmentDetails.basicDetails.pan_file?.name}
                  </a>
                ) : (
                  "No attachment"
                )}
              </td>
            </tr>
          </WishPreviewSection>
        </div>
        <div className="col-sm-6">
          <WishPreviewSection
            title={PageTitles[1]}
            onEditClicked={() => {
              setCurrentPage(1);
            }}
          >
            <tr>
              <td>Full Name</td>
              <td>{enrollmentDetails.basicDetails.first_name + " " + enrollmentDetails.basicDetails.second_name} </td>
            </tr>
            <tr>
              <td>Mobile Number</td>
              <td>{enrollmentDetails.contactDetails.phone}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{enrollmentDetails.contactDetails.email}</td>
            </tr>
            <tr>
              <td>Permanent Address</td>
              <td>
                {enrollmentDetails?.contactDetails?.permanent_address_line1}
                <br />
                {enrollmentDetails?.contactDetails?.permanent_address_line2}
                <br />
                {enrollmentDetails?.contactDetails?.permanent_post_name +
                  " " +
                  enrollmentDetails?.contactDetails?.permanent_city_name +
                  " " +
                  enrollmentDetails?.contactDetails?.permanent_district_name}
                <br />
                {enrollmentDetails?.contactDetails?.permanent_state_name +
                  " " +
                  enrollmentDetails?.contactDetails?.permanent_country_name +
                  " " +
                  enrollmentDetails?.contactDetails?.permanent_postalcode}
              </td>
            </tr>
            <tr>
              <td>Communication Address</td>
              <td>
                {enrollmentDetails?.contactDetails?.communication_address_line1}
                <br />
                {enrollmentDetails?.contactDetails?.communication_address_line2}
                <br />
                {enrollmentDetails?.contactDetails?.communication_post_name +
                  " " +
                  enrollmentDetails?.contactDetails?.communication_city_name +
                  " " +
                  enrollmentDetails?.contactDetails?.communication_district_name}
                <br />
                {enrollmentDetails?.contactDetails?.communication_state_name +
                  " " +
                  enrollmentDetails?.contactDetails?.communication_country_name +
                  " " +
                  enrollmentDetails?.contactDetails?.communication_postalcode}
              </td>
            </tr>
            <tr>
              <td>Permanent Address Proof (Attachment)</td>
              <td className="">
                {enrollmentDetails.contactDetails.address_proof_file?.name ? (
                  <a href="javascript:void();">
                    <i className="las la-file-alt la-2x"></i>
                    {enrollmentDetails.contactDetails.address_proof_file?.name}
                  </a>
                ) : (
                  "No attachment"
                )}
              </td>
            </tr>
          </WishPreviewSection>
        </div>
        <div className="col-sm-6">
          <WishPreviewSection
            title={PageTitles[2]}
            onEditClicked={() => {
              setCurrentPage(2);
            }}
          >
            <tr>
              <td>Account Holder</td>
              <td>{enrollmentDetails.bankDetails.account_name}</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>{enrollmentDetails.bankDetails.account_no}</td>
            </tr>
            <tr>
              <td>IFSC Code</td>
              <td>{enrollmentDetails.bankDetails.ifsc}</td>
            </tr>
            <tr>
              <td>Bank Name</td>
              <td>{enrollmentDetails.bankDetails.bank_name}</td>
            </tr>
            <tr>
              <td>Bank Branch</td>
              <td>{enrollmentDetails.bankDetails.branch_name}</td>
            </tr>
            <tr>
              <td>Bank Address Proof (Attachment)</td>
              <td className="">
                {enrollmentDetails.bankDetails.bank_proof_file?.name ? (
                  <a href="javascript:void();">
                    <i className="las la-file-alt la-2x"></i>
                    {enrollmentDetails.bankDetails.bank_proof_file?.name}
                  </a>
                ) : (
                  "No attachment"
                )}
              </td>
            </tr>
          </WishPreviewSection>
        </div>
        <div className="col-sm-6">
          <WishPreviewSection
            title={PageTitles[3]}
            onEditClicked={() => {
              setCurrentPage(3);
            }}
          >
            <tr>
              <td>Co-Applicant Name</td>
              <td>{enrollmentDetails.coApplicantDetails.coapplicant_name}</td>
            </tr>
            <tr>
              <td>Co-Applicant Date of birth</td>
              <td>{enrollmentDetails.coApplicantDetails.coapplicant_dob ? new Date(enrollmentDetails.coApplicantDetails.coapplicant_dob).toDateString() : "No data found"}</td>
            </tr>
            <tr>
              <td>Mobile Number</td>
              <td>{enrollmentDetails.coApplicantDetails.coapplicant_phone}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{enrollmentDetails.coApplicantDetails.coapplicant_email}</td>
            </tr>
            <tr>
              <td>Relationship</td>
              <td>
                {
                  enrollmentMasterData?.relationship?.find((item) => {
                    return item.id === enrollmentDetails.coApplicantDetails.coapplicant_relationship_id;
                  })?.title_name
                }
              </td>
            </tr>
            <tr>
              <td>Co-Applicant PAN</td>
              <td>{enrollmentDetails.coApplicantDetails.coapplicant_pan_no}</td>
            </tr>
            <tr>
              <td>PAN (Attachment)</td>
              <td className="">
                {enrollmentDetails.coApplicantDetails.coapp_pan_file?.name ? (
                  <a href="javascript:void();">
                    <i className="las la-file-alt la-2x"></i>
                    {enrollmentDetails.coApplicantDetails.coapp_pan_file?.name}
                  </a>
                ) : (
                  "No attachment"
                )}
              </td>
            </tr>
            <tr>
              <td>Account Holder</td>
              <td>{enrollmentDetails.coApplicantDetails.coapplicant_account_name}</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>{enrollmentDetails.coApplicantDetails.coapplicant_account_no}</td>
            </tr>
            <tr>
              <td>IFSC Code</td>
              <td>{enrollmentDetails.coApplicantDetails.coapplicant_ifsc}</td>
            </tr>
            <tr>
              <td>Bank Name</td>
              <td>{enrollmentDetails.coApplicantDetails.coapplicant_bank_name}</td>
            </tr>
            <tr>
              <td>Bank Branch</td>
              <td>{enrollmentDetails.coApplicantDetails.coapplicant_branch_name}</td>
            </tr>
            <tr>
              <td>Bank Address Proof (Attachment)</td>
              <td className="">
                {enrollmentDetails.coApplicantDetails.coapp_bank_proof_file?.name ? (
                  <a href="javascript:void();">
                    <i className="las la-file-alt la-2x"></i>
                    {enrollmentDetails.coApplicantDetails.coapp_bank_proof_file?.name}
                  </a>
                ) : (
                  "No attachment"
                )}
              </td>
            </tr>
            <tr>
              <td>Nominee Name</td>
              <td>{enrollmentDetails.coApplicantDetails.nominee}</td>
            </tr>
            <tr>
              <td>Nominee Relationship</td>
              <td>
                {
                  enrollmentMasterData?.relationship?.find((item) => {
                    return item.id === enrollmentDetails.coApplicantDetails.nominee_mrelationship_id;
                  })?.title_name
                }
              </td>
            </tr>
          </WishPreviewSection>
        </div>
      </div>
      <div className="col-12">
        <div className="alert alert-warning d-flex justify-content-between align-items-center" role="alert">
          Terms and Conditions
          <a
            className="card-link link-dotted "
            onClick={() => {
              downloadTerms();
            }}
          >
            Download (T&amp;C)
          </a>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <div className="custom-control custom-checkbox custom-control-inline">
              <input
                name="chkTerms"
                id="chkTerms_0"
                type="checkbox"
                className="custom-control-input"
                checked={termsAgreed}
                readOnly
                onClick={() => {
                  setTermsAgreed(!termsAgreed);
                }}
              />
              <label for="chkTerms_0" className="custom-control-label">
                {terms[0]?.terms}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

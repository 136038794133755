//import logo from './logo.svg';
import React, { useState } from "react";
import { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import FallbackUI from "./views/NoNetworkFallback";
import EnrollUser from "./views/enrollment/EnrollUser";
import { DataProvider } from "./contexts/EnrollUserContext";

const App = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleConnectionChange = () => {
      setIsOnline(window.navigator.onLine);
    };
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);
    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  return (
    <div>
      {isOnline ? (
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <DataProvider>
                  <EnrollUser />
                </DataProvider>
              }
            />
          </Routes>
        </Router>
      ) : (
        // Render the fallback UI component
        <FallbackUI />
      )}
    </div>
  );
};

export default App;

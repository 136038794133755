import { useState, useEffect, useContext } from "react";
import WishToaster from "../components/WishToaster";
import { AppUtils } from "../services/AppUtils";
import useAPIs from "./useAPIs";
import EnrollmentContext from "../contexts/EnrollUserContext";

const useEnrollment = (language_id) => {
  const { tempDistId, setTempDistId, enrollmentDetails, setEnrollmentDetails, currentSection, setCurrentSection, enrollmentLoading, setLoading } = useContext(EnrollmentContext);
  const { apiError, processing, postData, postFormData, getData } = useAPIs();
  const [enrollmentMasterData, setEnrollmentMasterData] = useState(null);
  const [enrollmentError, setError] = useState(null);
  const [terms, setTerms] = useState({});
  const [locationDetails, setLocationDetaills] = useState(null);

  useEffect(() => {
    setError(apiError);
  }, [apiError]);

  useEffect(() => {
    setLoading(processing);
  }, [processing]);

  useEffect(() => {
    if (language_id) {
      // Load all init data here
      getData("/enrollment/link/fetch-enrolment-form-master-data", (data) => {
        setEnrollmentMasterData(data);
      });
      if (language_id) {
        getData(`/enrollment/link/fetch-terms-and-conditions?language_id=${language_id}`, (data) => {
          setTerms(data);
        });
      }
    }
  }, [language_id]);

  useEffect(() => {
    if (enrollmentError) {
      //setLoading(false);
    }
  }, [enrollmentError]);

  useEffect(() => {
    console.log(enrollmentLoading, "loading inside hook");
  }, [enrollmentLoading]);

  const getLocationDetails = (pincode, onSuccess) => {
    //setLoading(true);
    getData(`/sales/v1/bo/location/list/${pincode}`, (data) => {
      //setLoading(false);
      setLocationDetaills(data);
      onSuccess(data);
    });
  };

  const saveEnrolleeDetails = (link_id, pageNumber, payload, onSuccess) => {
    console.log(link_id, pageNumber, payload, "saveEnrolleeDetails");
    setLoading(true);
    let formData;
    const pages = ["personal-details", "contact-details", "bank-details", "coapp-details"];
    switch (pageNumber) {
      case 0:
        payload.basicDetails.pan_declaration = payload.basicDetails.pan_declaration ? 1 : 0;
        payload.basicDetails.gst_declaration = payload.basicDetails.gst_declaration ? 1 : 0;
        payload.basicDetails.link_id = link_id;
        formData = AppUtils.createFormData(payload.basicDetails);
        break;
      case 1:
        payload.contactDetails.link_id = link_id;
        formData = AppUtils.createFormData(payload.contactDetails);
        break;
      case 2:
        payload.bankDetails.link_id = link_id;
        formData = AppUtils.createFormData(payload.bankDetails);
        break;
      case 3:
        payload.coApplicantDetails.link_id = link_id;
        formData = AppUtils.createFormData(payload.coApplicantDetails);
        break;
      default:
        break;
    }

    let savesection = {
      link_id: link_id,
      distributor_id: payload?.basicDetails?.distributor_id,
      dist_temp_id: tempDistId,
      section_level: currentSection,
    };
    if (pageNumber < 4) {
      postFormData(
        `/enrollment/link/store-temp-${pages[pageNumber]}`,
        formData,
        (data) => {
          if (pageNumber === 0) {
            setTempDistId(data.dist_temp_id);
            savesection.dist_temp_id = data.dist_temp_id;
            setEnrollmentDetails({
              ...enrollmentDetails,
              basicDetails: { ...enrollmentDetails.basicDetails, dist_temp_id: data.dist_temp_id },
              contactDetails: { ...enrollmentDetails.contactDetails, dist_temp_id: data.dist_temp_id },
              bankDetails: { ...enrollmentDetails.bankDetails, dist_temp_id: data.dist_temp_id },
              coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, dist_temp_id: data.dist_temp_id },
              placementDetails: { ...enrollmentDetails.placementDetails, dist_temp_id: data.dist_temp_id },
            });
            saveSectionDone(pageNumber, savesection);
          } else {
            saveSectionDone(pageNumber, savesection);
          }
          onSuccess(data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      );
    } else {
      onSuccess();
      setLoading(false);
      saveSectionDone(pageNumber, savesection);
    }
  };

  const saveSectionDone = (pageNumber, savesection) => {
    console.log(pageNumber, currentSection);
    if (savesection.dist_temp_id && pageNumber < 5 && pageNumber === currentSection - 1) {
      postData(`/enrollment/link/store-temp-enrollee-section-level`, savesection, (data) => {
        console.log(data);
        setCurrentSection(currentSection + 1);
      });
    }
  };

  const getBankBranchDetails = (ifscCode, onSuccess) => {
    getData(`/enrollment/link/fetch-bank-details?ifsc_code=${ifscCode}`, (data) => {
      onSuccess(data);
    });
  };
  const getCoAppRelationships = (genderId, maritialStatusId, onSuccess) => {
    getData(`/enrollment/link/fetch-coapp-relationships-list?gender_id=${genderId}&marital_status_id=${maritialStatusId}`, (data) => {
      onSuccess(data);
    });
  };
  const acceptTerms = (payload = {}, onSuccess) => {
    payload.dist_temp_id = tempDistId;
    postData(`/enrollment/link/accept-terms-and-conditions`, payload, (data) => {
      onSuccess(data);
    });
  };
  const downloadTerms = (payload = {}) => {
    payload.dist_temp_id = tempDistId;
    postData(
      `/enrollment/link/download-dist-contract`,
      payload,
      (data) => {
        window.open(data.contract_file_url);
      },
      (error) => {
        WishToaster({
          toastMessage: "Something went wrong!",
          toastType: "error",
        });
      }
    );
  };
  const validatePostion = (payload, onSuccess, onError) => {
    postData(
      `/enrollment/link/validate-enrollment-position`,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };
  const enrollUser = (payload, onSuccess, onError) => {
    payload.dist_temp_id = tempDistId;
    postData(
      `/enrollment/link/create-new-distributor`,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError && onError(error);
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
      }
    );
  };
  const fetchLinkDetails = (link_id, onSuccess, onError) => {
    postData(
      `/enrollment/link/fetch-enrolment-link-details`,
      {
        link_id: link_id,
      },
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };
  const fetchEnrolleeDetails = (payload, onSuccess, onError) => {
    postData(
      `/enrollment/link/fetch-temp-enrollee-details`,
      payload,
      (data) => {
        setTempDistId(data.dist_temp_id);
        setCurrentSection(data.section_level + 1);
        onSuccess(data);
      },
      (error) => {
        onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };
  const checkPhone = async (phone, onSuccess, onError) => {
    setLoading(true);
    await postData(
      `/enrollment/phone-verify`,
      {
        phone: phone,
      },
      (data) => {
        setLoading(false);
        onSuccess && onSuccess(data);
      },
      (error) => {
        setLoading(false);
        onError && onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };
  const checkEmail = async (email, onSuccess, onError) => {
    await postData(
      `/enrollment/email-verify`,
      {
        email: email,
      },
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };
  const checkPAN = async (pan, onSuccess, onError) => {
    await postData(
      `/enrollment/pan-validation`,
      {
        pan_no: pan,
      },
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  return {
    enrollmentError,
    enrollmentLoading,
    enrollmentMasterData,
    terms,
    getLocationDetails,
    saveEnrolleeDetails,
    tempDistId,
    getBankBranchDetails,
    getCoAppRelationships,
    acceptTerms,
    downloadTerms,
    validatePostion,
    enrollUser,
    fetchEnrolleeDetails,
    fetchLinkDetails,
    checkPhone,
    checkEmail,
    checkPAN,
  };
};

export default useEnrollment;

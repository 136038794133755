import WishLinkCard from "../../../components/WishLinkCard";
import { useValidatePage } from "./ValidatePage";

export default function NavigationBar({ currentPage, termsAgreed, canNavigate }) {
  const validatePage = useValidatePage();
  const navigations = ["Applicant Details", "Contact Details", "Bank Details", "Co-Applicant Details", "Preview"];
  const totalPages = navigations.length;

  var currentProgress = ((currentPage + 1) / totalPages) * 100;
  return (
    <div className="row">
      {navigations.map((navigation, index) => {
        return (
          <div className="col-sm-auto col-lg col-md" key={index}>
            <WishLinkCard
              key={index}
              linkTitle={navigation}
              background={
                currentPage === index ? "bg-primary white text-white link-white box-shadow-3 mb-1" : termsAgreed === true ? "bg-success white text-white link-white mb-1" : "mb-1"
              }
              onClick={() => {
                if (canNavigate) {
                  validatePage(index);
                }
              }}
            ></WishLinkCard>
          </div>
        );
      })}
      <div className="col-12">
        <div className="progress" style={{ height: "3px" }}>
          <div className="progress-bar" role="progressbar" style={{ width: currentProgress + "%" }} aria-valuenow={currentProgress} aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
  );
}

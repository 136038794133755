import { createContext, useState, useEffect } from "react";
import _ from "lodash";
// import { useSearchParams } from "react-router-dom";
import useSessionStorage from "../services/useSessionStorage";

const EnrollmentContext = createContext({});

export const DataProvider = ({ children }) => {
  const PersonalDetails = {
    distributor_id: "",
    section_level: 1,
    dist_temp_id: "",
    title_id: 1,
    first_name: (String, ""),
    second_name: (String, ""),
    dob: (String, ""),
    language_id: 1,
    gender_id: 1,
    marital_status_id: 1,
    profession_id: 1,
    monthly_income_id: 1,
    aadhar_no: (String, ""),
    aadhar_file: (String, ""),
    pan_no: (String, ""),
    pan_file: (String, ""),
    gst_no: (String, ""),
    pan_declaration: 0,
    gst_declaration: 0,
  };
  const ContactDetails = {
    distributor_id: "",
    section_level: 2,
    dist_temp_id: "",
    email: "",
    phone: "",
    permanent_address_line1: "",
    permanent_address_line2: "",
    permanent_postalcode_id: "",
    permanent_postalcode: "",
    permanent_post_name: "",
    permanent_city_id: "",
    permanent_district_id: "",
    permanent_state_id: "",
    permanent_country_id: "",
    permanent_city_name: "",
    permanent_district_name: "",
    permanent_state_name: "",
    permanent_country_name: "",
    is_addresses_same: 1,
    communication_address_line1: "",
    communication_address_line2: "",
    communication_postalcode_id: "",
    communication_postalcode: "",
    communication_post_name: "",
    communication_city_id: "",
    communication_district_id: "",
    communication_state_id: "",
    communication_country_id: "",
    communication_city_name: "",
    communication_district_name: "",
    communication_state_name: "",
    communication_country_name: "",
    address_proof_file: "",
  };
  const BankDetails = {
    distributor_id: "",
    section_level: 3,
    dist_temp_id: "",
    bank_name: "",
    branch_name: "",
    ifsc: "",
    account_no: "",
    confirm_account_no: "",
    account_name: "",
    bank_id: 1,
    bank_proof_file: "",
  };
  const CoAppDetails = {
    distributor_id: "",
    section_level: 4,
    dist_temp_id: "",
    coapplicant_name: "",
    coapplicant_phone: "",
    coapplicant_email: "",
    coapplicant_dob: "",
    coapplicant_gender_id: 1,
    coapplicant_relationship_id: "",
    coapplicant_pan_no: "",
    coapplicant_bank_name: "",
    coapplicant_branch_name: "",
    coapplicant_ifsc: "",
    coapplicant_account_no: "",
    coapplicant_confirm_account_no: "",
    coapplicant_account_name: "",
    coapplicant_bank_id: 1,
    nominee: "",
    nominee_mrelationship_id: "",
    coapp_pan_file: "",
    coapp_bank_proof_file: "",
  };
  const PlacementDetails = {
    distributor_id: "",
    dist_temp_id: "",
    placement_distributor_id: "",
    placement_position_id: "",
    sponsor_distributor_id: "",
    sponsor_distributor_name: "",
    password: "",
    confirm_password: "",
  };
  const initialEnrollmentDetails = {
    basicDetails: _.cloneDeep(PersonalDetails),
    contactDetails: _.cloneDeep(ContactDetails),
    bankDetails: _.cloneDeep(BankDetails),
    coApplicantDetails: _.cloneDeep(CoAppDetails),
    placementDetails: _.cloneDeep(PlacementDetails),
  };

  const [enrollmentDetails, setEnrollmentDetails] = useState(initialEnrollmentDetails);
  const [isPageDirty, setIsPageDirty] = useState(true);
  const [pageError, setPageError] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [tempDistId, setTempDistId] = useState("");
  const [currentSection, setCurrentSection] = useState(1);
  const [enrollmentLoading, setLoading] = useState(false);
  // const [searchParams] = useSearchParams();
  const [linkID, setLinkID] = useSessionStorage("link_id", ""); // Use the custom hook

  const PageTitles = ["Applicant | Basic Details", "Applicant | Contact Details", "Applicant | Bank Details", "Co-Applicant Details", "Preview", "Placement Details"];

  // useEffect(() => {
  //   console.log("enrollmentDetails", searchParams.get("id"));
  //   const id = searchParams.get("id");
  //   if (id) {
  //     setLinkID(id);
  //   }
  // }, [searchParams, setLinkID]);

  return (
    <EnrollmentContext.Provider
      value={{
        enrollmentDetails,
        setEnrollmentDetails,
        isPageDirty,
        setIsPageDirty,
        pageError,
        setPageError,
        currentPage,
        setCurrentPage,
        termsAgreed,
        setTermsAgreed,
        PageTitles,
        linkID,
        setLinkID,
        tempDistId,
        setTempDistId,
        currentSection,
        setCurrentSection,
        enrollmentLoading,
        setLoading,
      }}
    >
      {children}
    </EnrollmentContext.Provider>
  );
};

export default EnrollmentContext;

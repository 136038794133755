import moment from "moment";

export const ValidationUtils = {
  isEmpty: function (value, defaultErrorMessage = "Value is required", onError) {
    if (value.trim() === "") {
      onError(defaultErrorMessage);
      return false;
    }
    return true;
  },

  isValid: function (value, regEx, defaultErrorMessage, onError) {
    console.log(value, regEx.test(value));
    if (!value.match(regEx)) {
      onError(defaultErrorMessage);
      return false;
    }
    return true;
  },
  isTruthy: function (value, defaultErrorMessage = "Value is required", onError) {
    if (!value) {
      onError(defaultErrorMessage);
      return false;
    }
    return true;
  },
  isSame: function (value1, value2, defaultErrorMessage, onError) {
    if (!value1) {
      onError("Enter account number");
      return false;
    }
    if (value1 === value2) {
      return true;
    }
    onError(defaultErrorMessage);
    return false;
  },

  validateDOB: function (dob, defaultErrorMessage, onError) {
    if (dob.trim() === "") {
      onError(defaultErrorMessage);
      return false;
    }

    var YearsBeforeToday = moment().subtract(18, "years");
    if (moment(dob).isSameOrAfter(YearsBeforeToday)) {
      onError("You must be 18 years or above to enroll");
      return false;
    }
    return true;
  },

  validateFile: function (file, defaultErrorMessage, onError) {
    if (file.name) {
      return true;
    }
    onError(defaultErrorMessage);
    return false;
  },
  customCondition: function (conditon, defaultErrorMessage, onError) {
    console.log(conditon);
    if (conditon) {
      return true;
    } else {
      onError(defaultErrorMessage);
      return false;
    }
  },
};

import { useContext } from "react";
import { AppUtils } from "../../../services/AppUtils";
import { ValidationUtils } from "../../../services/ValidationUtils";
import EnrollmentContext from "../../../contexts/EnrollUserContext";
import useEnrollment from "../../../services/useEnrollment";

export const useValidatePage = () => {
  const { enrollmentDetails, isPageDirty, setPageError, currentPage, setCurrentPage, setEnrollmentDetails, linkID } = useContext(EnrollmentContext);
  const { checkPAN, checkEmail, checkPhone, saveEnrolleeDetails } = useEnrollment(1);

  return async (navigateToPage = -1) => {
    let pageIsValid = true;
    setPageError(null);
    if (isPageDirty) {
      switch (currentPage) {
        case 0:
          pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.basicDetails?.first_name, "Please enter your first name", setPageError);
          pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.basicDetails?.second_name, "Please enter your last name", setPageError);
          pageIsValid = pageIsValid && ValidationUtils.validateDOB(enrollmentDetails.basicDetails?.dob, "Please enter date of birth", setPageError);
          pageIsValid =
            pageIsValid &&
            (!ValidationUtils.isEmpty(enrollmentDetails.basicDetails?.aadhar_no, "Kindly enter a valid Aadhar Number", () => {}) ||
              ValidationUtils.isValid(enrollmentDetails.basicDetails?.aadhar_no, /^[2-9]\d{11}$/, "Kindly enter a valid Aadhar Number", setPageError));
          pageIsValid =
            pageIsValid &&
            (!ValidationUtils.isEmpty(enrollmentDetails.basicDetails?.pan_no, "Kindly enter a valid PAN Number", () => {}) ||
              ValidationUtils.isValid(enrollmentDetails.basicDetails?.pan_no, /^[A-Z]{5}\d{4}[A-Z]$/, "Kindly enter a valid PAN Number", setPageError));
          pageIsValid =
            pageIsValid &&
            (!ValidationUtils.isEmpty(enrollmentDetails.basicDetails?.gst_no, "Kindly enter a valid GST Number", () => {}) ||
              ValidationUtils.isValid(
                enrollmentDetails.basicDetails?.gst_no,
                /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[A-Z0-9]{1}$/,
                "Kindly enter a valid GST Number",
                setPageError
              ));
          if (
            (enrollmentDetails.basicDetails.gst_no === "" && enrollmentDetails.basicDetails.gst_declaration === 0) ||
            (enrollmentDetails.basicDetails.pan_no === "" && enrollmentDetails.basicDetails.pan_declaration === 0)
          ) {
            console.log("reached");
            AppUtils.showDialog("dlgConsent");
            pageIsValid = false;
          }
          if (pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.basicDetails?.pan_no, "Kindly enter a valid PAN Number", () => {})) {
            let panCheckResult;
            await checkPAN(
              enrollmentDetails.basicDetails.pan_no,
              (data) => {
                if (data?.code === 200) {
                  panCheckResult = true;
                  const nameParts = data?.data?.full_name.split(" ");
                  const firstName = nameParts[0];
                  const lastName = nameParts.slice(1).join(" ");
                  setEnrollmentDetails({
                    ...enrollmentDetails,
                    basicDetails: { ...enrollmentDetails.basicDetails, first_name: firstName, second_name: lastName },
                  });
                }
              },
              (error) => {
                setPageError(error);
                panCheckResult = false;
              }
            );
            pageIsValid = pageIsValid && panCheckResult;
          }
          break;

        case 1:
          pageIsValid = pageIsValid && ValidationUtils.isValid(enrollmentDetails.contactDetails?.phone, /^[6-9]\d{9}$/, "Please enter a valid phone number", setPageError);
          pageIsValid =
            pageIsValid &&
            ValidationUtils.isValid(enrollmentDetails.contactDetails?.email, /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/, "Please enter a valid email", setPageError);
          pageIsValid =
            pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.contactDetails?.permanent_address_line1, "Please enter your permanent address - line 1", setPageError);
          pageIsValid =
            pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.contactDetails?.permanent_address_line2, "Please enter your permanent address - line 2", setPageError);
          pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.contactDetails?.permanent_postalcode_id.toString(), "Please enter pincode", setPageError);
          if (pageIsValid) {
            let phoneCheckResult;
            let emailCheckResult;
            await checkPhone(
              enrollmentDetails.contactDetails?.phone,
              () => {
                phoneCheckResult = true;
              },
              (error) => {
                setPageError(error);
                phoneCheckResult = false;
              }
            );
            await checkEmail(
              enrollmentDetails.contactDetails?.email,
              () => {
                emailCheckResult = true;
              },
              (error) => {
                setPageError(error);
                emailCheckResult = false;
              }
            );
            pageIsValid = pageIsValid && phoneCheckResult && emailCheckResult;
          }
          break;

        case 2:
          pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.bankDetails?.account_name, "Please enter your account name", setPageError);
          pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.bankDetails?.account_no, "Please enter your account number", setPageError);
          pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.bankDetails?.bank_name, "Please select your bank by entering a valid IFSC code", setPageError);
          pageIsValid =
            pageIsValid &&
            ValidationUtils.isSame(enrollmentDetails.bankDetails?.account_no, enrollmentDetails.bankDetails?.confirm_account_no, "Account numbers don't match", setPageError);
          break;

        case 3:
          pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.coApplicantDetails?.coapplicant_name, "Please enter co-applicant name", setPageError);
          pageIsValid = pageIsValid && ValidationUtils.validateDOB(enrollmentDetails.coApplicantDetails?.coapplicant_dob, "Please enter co-applicant date of birth", setPageError);
          pageIsValid =
            pageIsValid && ValidationUtils.isValid(enrollmentDetails.coApplicantDetails?.coapplicant_phone, /^[6-9]\d{9}$/, "Enter a valid 10 digit phone number", setPageError);
          // pageIsValid =
          //   pageIsValid &&
          //   ValidationUtils.isValid(
          //     enrollmentDetails.coApplicantDetails?.coapplicant_email,
          //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
          //     "Enter a valid email",
          //     setPageError
          //   );
          // pageIsValid =
          //   pageIsValid &&
          //   ValidationUtils.isEmpty(
          //     enrollmentDetails.coApplicantDetails?.coapplicant_relationship_id,
          //     "Select co-applicant relationship",
          //     setPageError
          //   );
          // pageIsValid =
          //   pageIsValid &&
          //   (!ValidationUtils.isEmpty(enrollmentDetails.coApplicantDetails?.coapplicant_pan_no, "Kindly enter a valid PAN Number", () => {}) ||
          //     ValidationUtils.isValid(enrollmentDetails.coApplicantDetails?.coapplicant_pan_no, /^[A-Z]{5}\d{4}[A-Z]$/, "Kindly enter a valid PAN Number", setPageError));
          // pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.coApplicantDetails?.coapplicant_account_name, "Enter co-applicant account name", setPageError);
          // pageIsValid =
          //   pageIsValid &&
          //   ValidationUtils.isSame(
          //     enrollmentDetails.coApplicantDetails?.coapplicant_account_no,
          //     enrollmentDetails.coApplicantDetails?.coapplicant_confirm_account_no,
          //     "Account numbers don't match",
          //     setPageError
          //   );
          // pageIsValid =
          //   pageIsValid &&
          //   ValidationUtils.isEmpty(enrollmentDetails.coApplicantDetails?.coapplicant_bank_name, "Please select your bank by entering a valid IFSC code", setPageError);
          break;

        case 4:
          pageIsValid = true;
          break;

        default:
          pageIsValid = true;
          break;
      }
    }

    if (pageIsValid === true && navigateToPage > -1) {
      saveEnrolleeDetails(linkID, currentPage, enrollmentDetails, () => {
        setPageError(null);
        setCurrentPage(navigateToPage);
      });
    }
  };
};

import WishFlexBox from "./WishFlexBox";

const WishPreviewSection = ({ data, title, titleBackground = "primary", showEdit = true, onEditClicked, children }) => {
  return (
    <>
      <div className="alert alert-primary" role="alert">
        <WishFlexBox>
          {title}
          {showEdit ? (
            <a onClick={onEditClicked}>
              <i className="las la-edit"></i>
            </a>
          ) : (
            <>&nbsp;</>
          )}
        </WishFlexBox>
      </div>
      <div className="">
        <table className="table table-borderless">
          <tbody>{children}</tbody>
        </table>
      </div>
    </>
  );
};

export default WishPreviewSection;

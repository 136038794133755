import { useContext, useEffect, useState } from "react";
import WishSelect from "../../../components/WishFormComponents/WishSelect";
import WishSingleLineText from "../../../components/WishFormComponents/WishSingleLineText";
import WishDateControl from "../../../components/WishFormComponents/WishDateControl";
import WishFileControl from "../../../components/WishFormComponents/WishFileControl";
import moment from "moment";
import WishModal from "../../../components/WishModal";
import ErrorSection from "./ErrorSection";
import EnrollmentContext from "../../../contexts/EnrollUserContext";

export default function BasicDetailsPage({ enrollmentMasterData }) {
  const { enrollmentDetails, setEnrollmentDetails, setIsPageDirty, pageError } = useContext(EnrollmentContext);

  const declarationModal = () => {
    return (
      <WishModal id="dlgConsent" modalSize="modal-xl" title="You are seeing this because">
        <div className="alert alert-danger" role="alert">
          You did not provide PAN Card / Aadhar Card details. Please provide your consent for non-provision of PAN Card / GST Number
        </div>
        <div className="form-group row">
          {enrollmentDetails?.basicDetails?.pan_no === "" ? (
            <>
              <div className="col-12 pb-2">
                <h5>PAN Card Consent</h5>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    name="checkbox"
                    id="checkbox_0"
                    type="checkbox"
                    checked={enrollmentDetails?.basicDetails?.pan_declaration ? true : false}
                    className="custom-control-input"
                    value="pancard"
                    onClick={(e) => {
                      setEnrollmentDetails({
                        ...enrollmentDetails,
                        basicDetails: { ...enrollmentDetails.basicDetails, pan_declaration: !enrollmentDetails.basicDetails.pan_declaration },
                      });
                    }}
                  />
                  <label for="checkbox_0" className="custom-control-label">
                    I hereby undertake that I am not an ASSESSEE as defined under the provisions of Income Tax, 1961. I do not have income exceeding the limit on which tax is
                    required to be paid and therefore do not have PAN No. I have not applied for PAN number either voluntarily or otherwise in the past. I hereby, further undertake
                    to keep the company informed, once I get PAN number allocated by Income Tax Department immediately. I therefore, authorize company to deduct Tax at the
                    appropriate rate in the absence of PAN number and exonerate company from all future tax liability that may arise because of non-compliance of Income Tax Act,
                    1961 from my end.
                  </label>
                </div>
              </div>
              <div className="col-12">
                <hr />
              </div>
            </>
          ) : (
            <></>
          )}

          {enrollmentDetails?.basicDetails?.gst_no === "" ? (
            <div className="col-12 pt-2">
              <h5>GST Number Consent</h5>
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  name="checkbox"
                  id="checkbox_1"
                  type="checkbox"
                  checked={enrollmentDetails?.basicDetails?.gst_declaration ? true : false}
                  className="custom-control-input"
                  value="gstnumber"
                  onClick={() => {
                    setEnrollmentDetails({
                      ...enrollmentDetails,
                      basicDetails: { ...enrollmentDetails.basicDetails, gst_declaration: !enrollmentDetails.basicDetails.gst_declaration },
                    });
                  }}
                />
                <label for="checkbox_1" className="custom-control-label">
                  I hereby undertake that I am not a supplier as defined under the provisions of Goods and Services Tax, 2017 requiring registration. I do not have turnover during
                  the current and previous year exceeding the limit which requires mandatory registration and GST law and therefore do not have GST No. I have not applied for GST
                  number either voluntarily or otherwise in the past. I hereby, further undertake to keep the company informed, once I get GST number allocated by GST Department
                  immediately. I therefore, authorize company to charge GST at the appropriate rate in the absence of GST number and exonerate company from all future tax liability
                  that may arise because of non-compliance of GST laws from my end.
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </WishModal>
    );
  };
  return (
    <div
      onChange={() => {
        setIsPageDirty(true);
      }}
    >
      <WishSelect
        data={enrollmentMasterData?.titles}
        label="Title"
        initialValue={enrollmentDetails?.basicDetails?.title_id}
        onSelect={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, title_id: parseInt(newValue) },
          });
        }}
      />
      <WishSingleLineText
        label="First Name"
        initialValue={enrollmentDetails?.basicDetails?.first_name}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, first_name: newValue.toUpperCase() },
          });
        }}
      />
      <WishSingleLineText
        label="Last Name"
        initialValue={enrollmentDetails?.basicDetails?.second_name}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, second_name: newValue.toUpperCase() },
          });
        }}
      />
      <WishDateControl
        label="Date of birth"
        initialValue={moment(enrollmentDetails?.basicDetails?.dob).format("YYYY-MM-DD")}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, dob: new Date(newValue).toISOString() },
          });
        }}
      />
      <WishSelect
        data={enrollmentMasterData?.languages}
        label="Language"
        initialValue={enrollmentDetails?.basicDetails?.language_id}
        onSelect={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, language_id: parseInt(newValue) },
          });
        }}
      />
      <WishSelect
        data={enrollmentMasterData?.gender}
        label="Gender"
        initialValue={enrollmentDetails?.basicDetails?.gender_id}
        onSelect={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, gender_id: parseInt(newValue) },
          });
        }}
      />
      <WishSelect
        data={enrollmentMasterData?.marital_status}
        label="Marital Status"
        initialValue={enrollmentDetails?.basicDetails?.marital_status_id}
        onSelect={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, marital_status_id: parseInt(newValue) },
          });
        }}
      />
      <WishSelect
        data={enrollmentMasterData?.profession}
        label="Profession"
        initialValue={enrollmentDetails?.basicDetails?.profession_id}
        onSelect={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, profession_id: parseInt(newValue) },
          });
        }}
      />
      <WishSelect
        data={enrollmentMasterData?.monthly_income}
        label="Monthly Income"
        initialValue={enrollmentDetails?.basicDetails?.monthly_income_id}
        onSelect={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, monthly_income_id: parseInt(newValue) },
          });
        }}
      />
      <WishSingleLineText
        label="Aadhar Number (optional)"
        initialValue={enrollmentDetails?.basicDetails?.aadhar_no}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, aadhar_no: newValue },
          });
        }}
      />
      <WishFileControl
        label="Aadhar Document (attachment)"
        initialValue={enrollmentDetails?.basicDetails?.aadhar_file?.name}
        onChange={(fileName, fileObject) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, aadhar_file: fileObject },
          });
        }}
      />
      <WishSingleLineText
        label="PAN Number (optional)"
        initialValue={enrollmentDetails?.basicDetails?.pan_no}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, pan_no: newValue.toUpperCase().replace(/\s/g, "") },
          });
        }}
      />
      <WishFileControl
        filter="image/png, image/jpg, image/jpeg"
        label="PAN Document (attachment)"
        initialValue={enrollmentDetails?.basicDetails?.pan_file?.name}
        onChange={(fileName, fileObject) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, pan_file: fileObject },
          });
        }}
      />
      <WishSingleLineText
        label="GST Number (optional)"
        initialValue={enrollmentDetails?.basicDetails?.gst_no}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            basicDetails: { ...enrollmentDetails.basicDetails, gst_no: newValue.toUpperCase().replace(/\s/g, "") },
          });
        }}
      />
      <ErrorSection pageError={pageError} />
      {declarationModal()}
    </div>
  );
}

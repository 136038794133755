import { useContext, useEffect, useState } from "react";
import EnrollmentContext from "../../../contexts/EnrollUserContext";

export default function PlacementDetailsPage() {
  const { enrollmentDetails, setEnrollmentDetails } = useContext(EnrollmentContext);

  // const getPlacementPositions = () => {
  //   if (parseInt(enrollmentDetails.placementDetails.placement_distributor_id) > 1000) {
  //     getPositions(
  //       enrollmentDetails.placementDetails.placement_distributor_id,
  //       (data) => {
  //         setPostions(data.valid_position_ist);
  //         setEnrollmentDetails((prevState) => ({
  //           ...prevState,
  //           placementDetails: { ...prevState.placementDetails, placement_position_id: "" },
  //         }));
  //       },
  //       (error) => {
  //         console.log(error);
  //         setEnrollmentDetails((prevState) => ({
  //           ...prevState,
  //           placementDetails: { ...prevState.placementDetails, placement_position_id: "" },
  //         }));
  //         WishToaster({
  //           toastMessage: error,
  //           toastType: "error",
  //         });
  //       }
  //     );
  //   } else {
  //     WishToaster({
  //       toastMessage: "Enter a valid Placement ID",
  //       toastType: "error",
  //     });
  //   }
  // };
  // const changePositions = (e) => {
  //   setEnrollmentDetails((prevState) => ({
  //     ...prevState,
  //     placementDetails: { ...prevState.placementDetails, placement_position_id: parseInt(e.target.value) },
  //   }));
  // };

  // const handleSponsorCheck = (e) => {
  //   setLoading(true);
  //   validateSponsor(
  //     payload,
  //     (data) => {
  //       setEnrollmentDetails((prevState) => ({
  //         ...prevState,
  //         placementDetails: { ...prevState.placementDetails, sponsor_distributor_id: payload?.sponsor_distributor_id, sponsor_distributor_name: data?.name },
  //       }));
  //       setLoading(false);
  //     },
  //     (error) => {
  //       setEnrollmentDetails((prevState) => ({
  //         ...prevState,
  //         placementDetails: { ...prevState.placementDetails, sponsor_distributor_id: distFromLocal?.distributor_id, sponsor_distributor_name: distFromLocal?.name },
  //       }));
  //       setPayload((prevState) => ({
  //         ...prevState,
  //         sponsor_distributor_id: distFromLocal?.distributor_id,
  //         sponsor_distributor_name: distFromLocal?.name,
  //       }));
  //       setLoading(false);
  //     }
  //   );
  // };

  return (
    <div>
      <div className="form-group row">
        <label htmlFor="txtPlacementID" className="col-md-3 col-lg-3 col-sm-12 col-form-label">
          Placement ID
        </label>
        <div className="col-lg-9 col-md-9 col-sm-12">
          <input
            id="txtPlacementID"
            name="txtPlacementID"
            type="number"
            className="form-control"
            required="required"
            value={enrollmentDetails?.placementDetails?.placement_distributor_id}
            disabled
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="txtPlacementID" className="col-md-3 col-lg-3 col-sm-12 col-form-label">
          Position
        </label>
        <div className="col-lg-9 col-md-9 col-sm-12">
          <input
            id="txtPlacementID"
            name="txtPlacementID"
            type="number"
            className="form-control"
            required="required"
            value={enrollmentDetails.placementDetails?.placement_position_id}
            disabled
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="txtSponsorID" className="col-md-3 col-lg-3 col-sm-12 col-form-label">
          Sponsor ID
        </label>
        <div className="col-lg-9 col-md-9 col-sm-12">
          <input
            id="txtSponsorID"
            name="txtSponsorID"
            value={enrollmentDetails.placementDetails?.sponsor_distributor_id}
            disabled
            type="number"
            className="form-control"
            required="required"
          />
        </div>
      </div>
      {/* <div className="form-group row">
        <label htmlFor="txtSponsorName" className="col-md-3 col-lg-3 col-sm-12 col-form-label">
          Sponsor Name
        </label>
        <div className="col-lg-9 col-md-9 col-sm-12">
          <input
            id="txtSponsorName"
            name="txtSponsorName"
            readOnly
            value={enrollmentDetails.placementDetails?.sponsor_distributor_name}
            type="text"
            className="form-control"
            required="required"
          />
        </div>
      </div> */}
      <div className="form-group row">
        <label htmlFor="txtPassword" className="col-md-3 col-lg-3 col-sm-12 col-form-label">
          Password
        </label>
        <div className="col-lg-9 col-md-9 col-sm-12">
          <input
            id="txtPassword"
            name="txtPassword"
            value={enrollmentDetails?.placementDetails?.password}
            type="text"
            className="form-control"
            required="required"
            placeholder="Enter password"
            onChange={(e) => {
              setEnrollmentDetails((prevState) => ({
                ...prevState,
                placementDetails: { ...prevState.placementDetails, password: e.target.value },
              }));
            }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="txtConfirmPassword" className="col-md-3 col-lg-3 col-sm-12 col-form-label">
          Confirm Password
        </label>
        <div className="col-lg-9 col-md-9 col-sm-12">
          <input
            id="txtConfirmPassword"
            name="txtConfirmPassword"
            value={enrollmentDetails?.placementDetails?.confirm_password}
            type="text"
            className="form-control"
            required="required"
            placeholder="Enter password"
            onChange={(e) => {
              setEnrollmentDetails((prevState) => ({
                ...prevState,
                placementDetails: { ...prevState.placementDetails, confirm_password: e.target.value },
              }));
            }}
          />
        </div>
      </div>
    </div>
  );
}

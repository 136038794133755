import { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import useEnrollment from "../../services/useEnrollment";
import PageLayout from "../../components/PageLayout";
import WishToaster from "../../components/WishToaster";
import LoadingNote from "../../components/LoadingNote";
import WishCarousel from "../../components/WishCarousel";
import pageConfig from "../../data/config.json";
import BasicDetailsPage from "./components/BasicDetails";
import ContactDetailsPage from "./components/ContactDetails";
import BankDetailsPage from "./components/BankDetails";
import CoApplicantDetailsPage from "./components/CoapplicantDetails";
import { setDatatoEnrollForm } from "./components/EnrollmentUtils";
import NavigationBar from "./components/NavigationBar";
import PreviewPage from "./components/PreviewPage";
import { useValidatePage } from "./components/ValidatePage";
import EnrollmentContext from "../../contexts/EnrollUserContext";
import PlacementDetailsPage from "./components/PlacementDetails";

const SwalAlert = withReactContent(Swal);

const EnrollUser = () => {
  const [searchParams] = useSearchParams();
  const { enrollmentDetails, setEnrollmentDetails, currentPage, setCurrentPage, termsAgreed, linkID, setLinkID, enrollmentLoading, setLoading } = useContext(EnrollmentContext);
  const PageTitles = ["Applicant | Basic Details", "Applicant | Contact Details", "Applicant | Bank Details", "Co-Applicant Details", "Preview", "Placement Details"];
  const [canNavigate, setCanNavigate] = useState(false);
  const validatePage = useValidatePage();
  const { enrollmentError, enrollmentMasterData, acceptTerms, validatePostion, enrollUser, fetchEnrolleeDetails, fetchLinkDetails, saveEnrolleeDetails } = useEnrollment(1);

  useEffect(() => {
    console.log(enrollmentLoading, "loading");
  }, [enrollmentLoading]);

  useEffect(() => {
    setLinkID(searchParams.get("id"));
    console.log(linkID, "link id");
    if (!searchParams.get("id")) {
      SwalAlert.fire({
        icon: "error",
        title: "Uh Oh!",
        text: `No ID found in the provided link. Please check the link you provided.`,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      return;
    }
    if (linkID) {
      fetchLinkDetails(
        linkID,
        (data) => {
          let dataToPopulate = {
            dist_temp_id: data.linkDetails.dist_temp_id,
            distributor_id: data.linkDetails.distributor_id,
            sponsor_distributor_id: data.linkDetails.distributor_id,
            placement_position_id: data.linkDetails.distributor_placement_mposition_id,
            placement_distributor_id: data.linkDetails.distributor_placement_distributor_id,
            link_id: linkID,
          };
          console.log(dataToPopulate);
          sessionStorage.setItem("x-auth", data.token);
          if (data.linkDetails.dist_temp_id) {
            fetchEnrolleeDetails({ link_id: linkID, distributor_id: data.linkDetails?.distributor_id, dist_temp_id: data.linkDetails?.dist_temp_id }, (details) => {
              setCurrentPage(details.section_level);
              details.placement_position_id = data.linkDetails.distributor_placement_mposition_id;
              details.placement_distributor_id = data.linkDetails.distributor_placement_distributor_id;
              setDatatoEnrollForm(details, setEnrollmentDetails, linkID);
            });
          } else {
            setDatatoEnrollForm(dataToPopulate, setEnrollmentDetails, linkID);
          }
        },
        (error) => {
          SwalAlert.fire({
            icon: "error",
            title: "Uh Oh!",
            text: error,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      );
    }
  }, [linkID]);

  useEffect(() => {
    if (enrollmentError) {
      WishToaster({ toastMessage: enrollmentError });
    }
  }, [enrollmentError]);

  useEffect(() => {
    console.log(enrollmentDetails, termsAgreed);
  }, [enrollmentDetails, termsAgreed]);

  useEffect(() => {
    if (termsAgreed) {
      acceptTerms({ link_id: linkID, distributor_id: enrollmentDetails.basicDetails.distributor_id }, (data) => {
        console.log(data);
      });
    }
  }, [termsAgreed]);

  const enrollNewUser = () => {
    if (enrollmentDetails.placementDetails.password && enrollmentDetails.placementDetails.confirm_password) {
      saveEnrolleeDetails(linkID, currentPage, enrollmentDetails, () => {
        validatePostion(enrollmentDetails.placementDetails, (data) => {
          console.log(data);
          if (data.is_valid) {
            enrollUser(enrollmentDetails.placementDetails, (data) => {
              console.log(data);
              SwalAlert.fire({
                icon: "success",
                title: "Congratulations!",
                text: `You have successfully enrolled ${data.name}`,
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then(() => {
                window.location.href = "https://in.indusviva.com";
              });
            });
          } else {
          }
        });
      });
    } else {
      WishToaster({
        toastMessage: "Passwords do not match.",
        toastType: "error",
      });
    }
  };

  return (
    <PageLayout {...pageConfig.enrollUser}>
      <NavigationBar currentPage={currentPage} termsAgreed={termsAgreed} canNavigate={canNavigate} />
      {enrollmentLoading ? <LoadingNote /> : null}
      <WishCarousel
        selectedPageIndex={currentPage}
        nextLinkTitle="PROCEED"
        showArrows
        showNextPrev={true}
        termsAgreed={termsAgreed}
        onNextClicked={() => {
          if (!canNavigate) setCanNavigate(true);
          validatePage(currentPage + 1);
        }}
        onPreviousClicked={() => {
          if (!canNavigate) setCanNavigate(true);
          validatePage(currentPage - 1);
        }}
        onFinishClicked={() => {
          enrollNewUser();
        }}
        title={<h5>{PageTitles[currentPage] || PageTitles[0]}</h5>}
      >
        <BasicDetailsPage enrollmentMasterData={enrollmentMasterData} />
        <ContactDetailsPage />
        <BankDetailsPage />
        <CoApplicantDetailsPage enrollmentMasterData={enrollmentMasterData} />
        <PreviewPage />
        <PlacementDetailsPage />
      </WishCarousel>
    </PageLayout>
  );
};

export default EnrollUser;
